import { createMuiTheme } from '@material-ui/core';

const cgCyan = '#4fd3f1';
const cgBlue = '#062779';
const cgYellow = '#f1c71b';
const cgGreen = '#8af3bc';

export const scTheme = {
  cgCyan,
  cgBlue,
  cgYellow,
  cgGreen,
  palette: {
    type: 'dark',
    primary: {
      light: '#7986cb',
      main: cgCyan,
      dark: '#303f9f',
      contrastText: '#000',
    },
    secondary: {
      // light: '#7986cb',
      main: cgBlue,
      // dark: '#303f9f',
      contrastText: '#fff',
    },
  },
};

export const muiTheme = createMuiTheme(scTheme);
