import { useHistory, useParams } from 'react-router-dom';

import NewHuntForm from '../../components/NewHuntForm';
import React from 'react';
import { Typography } from '@material-ui/core';
import { useAPI } from '../../hooks/useAPI';

const EditHunt = () => {
  const history = useHistory();
  let { id } = useParams();

  const mediaData = useAPI(id ? `/hunts/${id}` : null);
  const streamers = useAPI('/moderators/me/streamers');

  const allLoading = mediaData.loading && streamers.loading;

  return (
    <div>
      <Typography variant='h4'>
        {id ? 'Edit Bonus Hunt' : 'Create Bonus Hunt'}
      </Typography>

      {!id && streamers.data && (
        <NewHuntForm
          streamers={streamers.data}
          onSubmit={() => history.push('/hunts')}
        />
      )}
      {id && mediaData.data && streamers.data && (
        <NewHuntForm
          streamers={streamers.data}
          //   onSubmit={() => history.push('/hunts')}
          editRecord={{ id, values: mediaData.data }}
        />
      )}
      {id && allLoading && <div>loading</div>}
    </div>
  );
};
export default EditHunt;
