import Avatar from '@material-ui/core/Avatar';
import { DataGrid } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { useAPI } from '../hooks/useAPI';
import { useFetchAPI } from '../hooks/useFetchAPI';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 1000,
  },
  pointer: {
    cursor: 'pointer',
  },
});

const scoresColumns = [
  { field: 'score', headerName: 'Score', width: 150 },
  {
    field: 'streamer',
    headerName: 'Streamer',
    width: 250,
  },
];

const StyledDataGrid = styled(DataGrid)`
  .MuiDataGrid-row {
    cursor: pointer;
  }
`;

const TournamentsList = () => {
  const history = useHistory();
  const classes = useStyles();
  const fetchAPI = useFetchAPI();
  const tournaments = useAPI(
    '/tournaments',
    {},
    {
      limit: 1000,
      page: 1,
    }
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteTournamentById = async id => {
    const result = await fetchAPI(`/tournaments/${id}`, {
      method: 'DELETE',
    });
    if (result.deletedCount === 1) {
      history.push('/tournaments');
    }
  };

  if (tournaments.loading) return <div>loading</div>;

  const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);

    return (
      <>
        <TableRow {...otherProps}>
          <TableCell padding='checkbox'>
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {children}
        </TableRow>
        {isExpanded && (
          <TableRow>
            <TableCell padding='checkbox' />
            {expandComponent}
          </TableRow>
        )}
      </>
    );
  };

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label='tournaments list'>
        <TableHead>
          <TableRow>
            <TableCell padding='checkbox' />
            <TableCell>Title</TableCell>
            <TableCell>Leaderboard Image</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Updated</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(tournaments.data.docs || [])
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(d => ({ ...d, id: d._id }))
            .map(tournament => (
              <ExpandableTableRow
                key={tournament.id}
                expandComponent={
                  <TableCell colSpan='6'>
                    <div style={{ height: 400, width: 500 }}>
                      <StyledDataGrid
                        rows={tournament.results.map((score, index) => ({
                          ...score,
                          id: index + 1,
                        }))}
                        autoPageSize
                        columns={scoresColumns}
                      />
                    </div>
                  </TableCell>
                }
              >
                <TableCell component='th' scope='row'>
                  {tournament.title}
                </TableCell>
                <TableCell component='th' scope='row'>
                  <Avatar
                    variant='square'
                    alt='Leaderboard Image'
                    src={
                      tournament.leaderboardImg ||
                      'https://res.cloudinary.com/casinogrounds/image/upload/co_rgb:ffffff,dpr_auto,e_grayscale,o_29,z_0.4/v1630067702/streamers/CG.png'
                    }
                  />
                </TableCell>
                <TableCell component='th' scope='row'>
                  {tournament.startDate &&
                    format(new Date(tournament.startDate), 'MMMM dd HH:mm')}
                </TableCell>
                <TableCell>
                  {tournament.endDate &&
                    format(new Date(tournament.endDate), 'MMMM dd HH:mm')}
                </TableCell>
                <TableCell>{tournament.status}</TableCell>
                <TableCell>
                  {format(
                    new Date(tournament.createdAt),
                    'dd/MM/yyyy, HH:mm:ss'
                  )}
                </TableCell>
                <TableCell>
                  {format(
                    new Date(tournament.updatedAt),
                    'dd/MM/yyyy, HH:mm:ss'
                  )}
                </TableCell>
                <TableCell>
                  <EditIcon
                    className={classes.pointer}
                    onClick={() =>
                      history.push('/tournaments/' + tournament.id)
                    }
                  />
                  <DeleteIcon
                    className={classes.pointer}
                    onClick={() => deleteTournamentById(tournament.id)}
                  />
                </TableCell>
              </ExpandableTableRow>
            ))}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component='div'
        count={tournaments.data.docs?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TournamentsList;
