import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain="cg-streamers.eu.auth0.com"
      clientId="cuQJ2URJ5EhtT21v0DidO9IpAN8D9lww"
      redirectUri={window.location.origin}
      scope="offline_access openid profile email username clientid"
      audience="https://streamers.casinogrounds.com"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
