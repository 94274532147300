import EditHunt from './pages/Hunts/Edit';
import EditTournament from './pages/Tournaments/Edit';
import Home from './pages/Home';
import Hunts from './pages/Hunts/Hunts';
import MyMods from './pages/Moderators/MyMods';
import Overlays from './pages/Overlays';
import ProtectedRoute from './auth/ProtectedRoute';
import React from 'react';
import Streamers from './pages/Streamers';
import { Switch } from 'react-router-dom';
import Tournaments from './pages/Tournaments/Tournaments';

const Nav = () => {
  return (
    <Switch>
      <ProtectedRoute path='/hunts/new' component={EditHunt} />
      <ProtectedRoute path='/hunts/:id' component={EditHunt} />
      <ProtectedRoute path='/hunts' component={Hunts} />
      <ProtectedRoute path='/tournaments/new' component={EditTournament} />
      <ProtectedRoute path='/tournaments/:id' component={EditTournament} />
      <ProtectedRoute path='/tournaments' component={Tournaments} />
      <ProtectedRoute path='/mymods' component={MyMods} />
      <ProtectedRoute path='/overlays' component={Overlays} />
      <ProtectedRoute path='/streamers' component={Streamers} />
      <ProtectedRoute path='/' component={Home} />
    </Switch>
  );
};
export default Nav;
