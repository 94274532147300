import React, { useState } from 'react';
import { Tooltip, Typography } from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FormControl from '@material-ui/core/FormControl';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import NumberFormat from 'react-number-format';
import Select from '@material-ui/core/Select';
import SendIcon from '@material-ui/icons/Send';
import TextField from '@material-ui/core/TextField';
import clone from 'clone';
import deepEqual from 'deep-equal';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { useFetchAPI } from '../hooks/useFetchAPI';

const FormWrap = styled.div``;

const getCurrencyFormatComp = currency => {
  return props => <NumberFormatCustom {...props} prefix={currency} />;
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
}));

const FormLine = styled.div`
  display: flex;
  max-width: 300px;
  align-items: center;
  margin-left: 8px;
`;

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix={props.prefix}
    />
  );
}

const SlotRow = styled.div`
  display: flex;
  align-items: flex-end;
  height: 55px;
`;

const removeEmptySlots = slots => {
  return slots.filter(s => {
    if (!s.name && !s.betSize && !s.result) {
      return false;
    }
    return true;
  });
};

const Spacer = styled.div`
  height: ${({ height }) => height || '20'}px;
`;

const SlorRowNumber = styled.div`
  font-size: 18px;
`;

const NewHunt = props => {
  const DEFAULT_VALUES = {
    title: '',
    startingBalance: '',
    currency: '€',
    slots: [],
    casinoId: '',
    phase: 'opening',
    streamer: props.streamers[0].auth0_id,
    huntResult: '',
    closestGuess: '',
    winnerName: '',
    winAmount: '',
  };

  const classes = useStyles();
  const fetchAPI = useFetchAPI();

  const [values, setValues] = React.useState(
    props.editRecord?.values
      ? {
          DEFAULT_VALUES,
          ...props.editRecord.values,
          streamer: props.editRecord.values.streamer.auth0_id,
          phase: 'opening',
        }
      : DEFAULT_VALUES
  );
  const [savedState, setSavedState] = useState(clone(values));

  const [CurrencyFormatCustom, setCurrencyFormatCustom] = useState(() =>
    getCurrencyFormatComp(values.currency)
  );

  const handleAddSlot = () => {
    setValues({
      ...values,
      slots: [
        ...values.slots,
        { name: '', betSize: '', result: '', key: new Date().getTime() },
      ],
    });
  };

  const handleUpdateSlot = (index, prop, event) => {
    const newSlots = [...values.slots];
    const value = event.target.value;
    newSlots[index][prop] = value;

    setValues({
      ...values,
      slots: newSlots,
    });
  };

  const handleDeleteSlot = slot => {
    const newSlots = [...values.slots];

    setValues({
      ...values,
      slots: newSlots.filter(s => s.key !== slot.key),
    });
  };

  const handleChange = prop => event => {
    let value = event.target.value;
    if (prop === 'currency') {
      setCurrencyFormatCustom(() => getCurrencyFormatComp(value));
    }
    setValues({ ...values, [prop]: value });
  };

  const handleSubmit = async () => {
    const editId = props.editRecord?.id;
    const cleanValues = { ...values, slots: removeEmptySlots(values.slots) };
    if (editId) delete cleanValues.streamer;
    setValues(cleanValues);

    try {
      const result = await fetchAPI(`/hunts${editId ? '/' + editId : ''}`, {
        method: editId ? 'PUT' : 'POST',
        body: JSON.stringify(cleanValues),
      });

      if (result.success && props.onSubmit) {
        props.onSubmit();
      } else {
        setSavedState(clone(cleanValues));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const isSubmittable = !props.editRecord || !deepEqual(values, savedState);

  return (
    <div>
      <Spacer />
      <FormWrap>
        {!props.editRecord && (
          <FormControl
            className={classes.formControl}
            style={{ marginLeft: 10 }}
          >
            <InputLabel id='demo-simple-select-label'>Streamer</InputLabel>
            <Select
              value={values.streamer}
              onChange={handleChange('streamer')}
              style={{ width: '200px' }}
              // defaultValue={props.streamers[0].auth0_id}
            >
              {props.streamers.map(s => {
                return (
                  <MenuItem key={s.auth0_id} value={s.auth0_id}>
                    {s.username}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        {props.editRecord && (
          <Typography>
            Streamer: {props.editRecord.values.streamer.username}
          </Typography>
        )}
        <Spacer />
        <FormControl className={classes.margin}>
          <TextField
            label={'Title'}
            value={values.title}
            onChange={handleChange('title')}
          />
        </FormControl>
        <Spacer />
        <FormLine>
          <FormControl className={classes.formControl}>
            <InputLabel id='demo-simple-select-label'>Currency</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={values.currency}
              onChange={handleChange('currency')}
              style={{ width: '60px', marginRight: '40px' }}
            >
              <MenuItem value={'€'}>€</MenuItem>
              <MenuItem value={'SEK'}>SEK</MenuItem>
              <MenuItem value={'kr'}>NOK</MenuItem>
              <MenuItem value={'£'}>£</MenuItem>
              <MenuItem value={'$'}>$</MenuItem>v
              <MenuItem value={''}></MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth className={classes.margin}>
            <TextField
              label='Starting Balance'
              value={values.startingBalance}
              onChange={handleChange('startingBalance')}
              id='formatted-numberformat-input'
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </FormControl>
        </FormLine>
        <Spacer />
        <TextField
          label='Casino'
          value={values.casinoId}
          onChange={handleChange('casinoId')}
          variant='outlined'
        />
        <Typography variant='caption'></Typography>
        <Spacer />
        <Spacer />
        <Typography variant='h5'>Slots</Typography>
        <Spacer />
        {values.slots.map((slot, index) => {
          return (
            <SlotRow key={slot.key}>
              <FormControl className={classes.margin}>
                <SlorRowNumber>{index + 1}.</SlorRowNumber>
              </FormControl>
              <FormControl className={classes.margin}>
                <TextField
                  label={(index === 0 || !slot.name) && 'Name'}
                  value={slot.name}
                  onChange={e => handleUpdateSlot(index, 'name', e)}
                />
              </FormControl>
              <FormControl className={classes.margin}>
                <TextField
                  value={slot.betSize}
                  label={(index === 0 || !slot.betSize) && 'Bet Size'}
                  onChange={e => handleUpdateSlot(index, 'betSize', e)}
                  InputProps={{
                    inputComponent: CurrencyFormatCustom,
                  }}
                />
              </FormControl>
              {values.phase === 'opening' && (
                <FormControl className={classes.margin}>
                  <TextField
                    value={slot.result}
                    label={(index === 0 || !slot.result) && 'Result'}
                    onChange={e => handleUpdateSlot(index, 'result', e)}
                    InputProps={{
                      inputComponent: CurrencyFormatCustom,
                    }}
                  />
                </FormControl>
              )}
              <IconButton onClick={() => handleDeleteSlot(slot)}>
                <DeleteIcon />
              </IconButton>
            </SlotRow>
          );
        })}

        <IconButton onClick={handleAddSlot}>
          <AddCircleIcon />
        </IconButton>
        <Spacer />

        <Typography variant='h5'>Results / Winner</Typography>
        <Spacer />

        <FormLine>
          <FormControl className={classes.margin}>
            <TextField
              label={'Closest Guess'}
              value={values.closestGuess}
              onChange={handleChange('closestGuess')}
              variant='outlined'
            />
          </FormControl>
        </FormLine>
        <FormLine>
          <FormControl className={classes.margin}>
            <TextField
              label={'Winner Name'}
              value={values.winnerName}
              onChange={handleChange('winnerName')}
              variant='outlined'
            />
          </FormControl>
        </FormLine>
        <FormLine>
          <FormControl className={classes.margin}>
            <TextField
              label={'Win Amount'}
              value={values.winAmount}
              onChange={handleChange('winAmount')}
              variant='outlined'
            />
          </FormControl>
          <Tooltip title='When left empty, win amount is hidden from the winner hunt'>
            <IconButton aria-label='help'>
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </FormLine>

        <br />
        <Spacer height={30} />
        <Button
          variant='contained'
          color='primary'
          startIcon={props.editRecord ? <EditIcon /> : <SendIcon />}
          onClick={handleSubmit}
          disabled={!isSubmittable}
        >
          {props.editRecord ? 'Update' : 'Save'}
        </Button>
      </FormWrap>
    </div>
  );
};

export default NewHunt;
