import ModeratorList from '../../components/ModeratorList';
import React from 'react';

const MyMods = () => {
  return (
    <div>
      <ModeratorList />
    </div>
  );
};
export default MyMods;
