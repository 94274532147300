import { FormControlLabel, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import Alert from '@material-ui/lab/Alert';
import clone from 'clone';
import { format } from 'date-fns';
import deepEqual from 'deep-equal';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useFetchAPI } from '../hooks/useFetchAPI';

const FormWrap = styled.div``;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  width_300: {
    width: '300px',
  },
  width_600: {
    width: '600px',
  },
  width_800: {
    width: '800px',
  },
  margin_switch: {
    margin: theme.spacing(3),
  },
  avatar: {
    height: theme.spacing(10),
    width: theme.spacing(10),
  },
}));

const ResultRow = styled.div`
  display: flex;
  align-items: flex-end;
  height: 55px;
`;

const removeEmptyResults = results => {
  return results.filter(s => {
    if (!s.streamer && !s.score) {
      return false;
    }
    return true;
  });
};

const Spacer = styled.div`
  height: ${({ height }) => height || '20'}px;
`;

const SlorRowNumber = styled.div`
  font-size: 18px;
`;

const NewTournament = props => {
  const DEFAULT_VALUES = {
    title: '',
    results: [],
    status: 'draft',
    leaderboardImg: '',
  };

  const classes = useStyles();
  const fetchAPI = useFetchAPI();

  const [values, setValues] = React.useState(
    props.editRecord?.values
      ? {
          DEFAULT_VALUES,
          ...props.editRecord.values,
        }
      : DEFAULT_VALUES
  );
  const [savedState, setSavedState] = useState(clone(values));
  const [isTournamentOpen, setIsTournamentOpen] = useState(
    props.editRecord?.values.status === 'running'
  );

  const handleSwitchChange = event => {
    setIsTournamentOpen(event.target.checked);
    if (event.target.checked) {
      setValues({
        ...values,
        status: 'running',
        startDate: new Date(),
        endDate: '',
      });
    } else {
      setValues({ ...values, status: 'closed', endDate: new Date() });
    }
  };

  const handleAddResult = () => {
    setValues({
      ...values,
      results: [...values.results, { streamer: '', score: '' }],
    });
  };

  const handleUpdateResult = (index, prop, event) => {
    const newResults = [...values.results];
    const value = event.target.value;
    newResults[index][prop] = value;

    setValues({
      ...values,
      results: newResults,
    });
  };

  const handleDeleteResult = result => {
    const newResults = [...values.results];

    setValues({
      ...values,
      results: newResults.filter(s => s.streamer !== result.streamer),
    });
  };

  const handleChange = prop => event => {
    let value = event.target.value;
    setValues({ ...values, [prop]: value });
  };

  const handleSubmit = async () => {
    const editId = props.editRecord?.id;
    const cleanValues = {
      ...values,
      results: removeEmptyResults(values.results),
    };
    setValues(cleanValues);

    try {
      const result = await fetchAPI(
        `/tournaments${editId ? '/' + editId : ''}`,
        {
          method: editId ? 'PUT' : 'POST',
          body: JSON.stringify(cleanValues),
        }
      );

      if (result._id && props.onSubmit) {
        props.onSubmit();
      } else {
        setSavedState(clone(cleanValues));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const isSubmittable = !props.editRecord || !deepEqual(values, savedState);

  return (
    <div>
      <Spacer />
      <FormWrap>
        {props.editRecord && (
          <Typography className={classes.margin}>
            Stream overlay URL:{' '}
            <Link
              component='button'
              onClick={() =>
                window.open(
                  `https://obs.streamers.casinogrounds.com/tournaments/${props.editRecord?.id}`,
                  '_blank'
                )
              }
            >{`https://obs.streamers.casinogrounds.com/tournaments/${props.editRecord?.id}`}</Link>
          </Typography>
        )}
        <FormControl className={`${classes.margin} ${classes.width_300}`}>
          <TextField
            label={'Title'}
            value={values.title}
            onChange={handleChange('title')}
          />
        </FormControl>
        <Spacer />
        <FormControl className={`${classes.margin} ${classes.width_800}`}>
          <TextField
            label={'Tournament Image URL'}
            value={values.leaderboardImg}
            onChange={handleChange('leaderboardImg')}
          />
          <Spacer />
          <Avatar
            variant='square'
            alt='Leaderboard Image'
            src={
              values.leaderboardImg ||
              'https://res.cloudinary.com/casinogrounds/image/upload/co_rgb:ffffff,dpr_auto,e_grayscale,o_29,z_0.4/v1630067702/streamers/CG.png'
            }
            className={classes.avatar}
          />
        </FormControl>
        <Spacer />
        <FormControl className={`${classes.margin} ${classes.width_300}`}>
          <TextField
            label={'Start Date'}
            disabled={true}
            value={
              (values.startDate &&
                format(new Date(values.startDate), 'MMMM dd HH:mm')) ||
              ''
            }
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <FormControl className={`${classes.margin} ${classes.width_300}`}>
          <TextField
            label={'End Date'}
            disabled={true}
            value={
              (values.endDate &&
                format(new Date(values.endDate), 'MMMM dd HH:mm')) ||
              ''
            }
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
        <Spacer />
        <FormControl className={`${classes.margin} ${classes.width_300}`}>
          <InputLabel id='select-status-label'>Status</InputLabel>
          <Select
            labelId='select-status-label'
            id='select-select'
            value={values.status}
            disabled
          >
            <MenuItem value={'draft'}>DRAFT</MenuItem>
            <MenuItem value={'running'}>RUNNING</MenuItem>
            <MenuItem value={'closed'}>CLOSED</MenuItem>
          </Select>
        </FormControl>

        <FormControlLabel
          className={`${classes.margin_switch} ${classes.width_300}`}
          control={
            <Switch
              color='primary'
              checked={isTournamentOpen}
              onChange={handleSwitchChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={
            isTournamentOpen
              ? 'Opened'
              : values.status === 'draft'
              ? 'Open/Close tournament'
              : 'Closed'
          }
        />

        {!isTournamentOpen && (
          <Alert
            severity='info'
            className={`${classes.margin} ${classes.width_600}`}
          >
            Tournament must be OPEN to use it as OBS widget.
          </Alert>
        )}

        <Typography variant='h5' className={classes.margin}>
          Results
        </Typography>
        <Spacer />
        {values.results?.map((result, index) => {
          return (
            <ResultRow key={index}>
              <FormControl className={classes.margin}>
                <SlorRowNumber>{index + 1}.</SlorRowNumber>
              </FormControl>
              <FormControl className={classes.margin}>
                <TextField
                  label={(index === 0 || !result.streamer) && 'Streamer'}
                  value={result.streamer}
                  disabled={isTournamentOpen}
                  onChange={e => handleUpdateResult(index, 'streamer', e)}
                />
              </FormControl>

              <FormControl className={classes.margin}>
                <TextField
                  type='number'
                  value={result.score}
                  label={(index === 0 || !result.score) && 'Score'}
                  onChange={e => handleUpdateResult(index, 'score', e)}
                />
              </FormControl>

              <FormControl className={classes.margin}>
                <TextField
                  value={result.scoreSign}
                  label={(index === 0 || !result.scoreSign) && 'Score Sign'}
                  onChange={e => handleUpdateResult(index, 'scoreSign', e)}
                />
              </FormControl>

              {!isTournamentOpen && (
                <IconButton onClick={() => handleDeleteResult(result)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </ResultRow>
          );
        })}

        {!isTournamentOpen && (
          <IconButton onClick={handleAddResult}>
            <AddCircleIcon />
          </IconButton>
        )}

        <Spacer height={30} />
        <Button
          variant='contained'
          color='primary'
          startIcon={props.editRecord ? <EditIcon /> : <SendIcon />}
          onClick={handleSubmit}
          disabled={!isSubmittable}
        >
          {props.editRecord ? 'Update' : 'Save'}
        </Button>
      </FormWrap>
    </div>
  );
};

export default NewTournament;
