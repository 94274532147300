import React from 'react';
import { Typography } from '@material-ui/core';
import { useAuthData } from '../hooks/useAuth';

const Overlays = () => {
  const { user } = useAuthData();
  if (!user.streamerKey || !user.roles.isStreamer) return null;

  if (!user) return null;
  return (
    <div>
      <Typography variant='h3'>Stream Overlays</Typography>
      <Typography style={{ marginTop: 20 }}>
        Use these personalized urls to show the Bonus Hunt overlays in your
        streaming software, e.g. OBS. The urls stay the same and always show the
        currently active Bonus Hunt.
      </Typography>
      <Typography variant='h5' style={{ marginTop: 20 }}>
        Centered Bonus Hunt Overlay
      </Typography>
      <Typography>Recommended resolution: 500x300px</Typography>
      <Typography
        variant='subtitle2'
        style={{
          maxWidth: '100vw',
          overflow: 'scroll',
          color: 'lightgrey',
          marginTop: 10,
        }}
      >{`https://obs.streamers.casinogrounds.com/hunts-centered/${user.streamerKey}`}</Typography>
      <img
        style={{ marginTop: 10 }}
        width='400'
        src='https://res.cloudinary.com/casinogrounds/image/upload/v1626884645/reactapp/bonus-hunt-centered.png'
        alt=''
      />

      <Typography variant='h5' style={{ marginTop: 20 }}>
        Large Bonus Hunt Overlay
      </Typography>
      <Typography>Recommended resolution: 600x300px</Typography>
      <Typography
        variant='subtitle2'
        style={{
          maxWidth: '100vw',
          overflow: 'scroll',
          color: 'lightgrey',
          marginTop: 10,
        }}
      >{`https://obs.streamers.casinogrounds.com/hunts/${user.streamerKey}`}</Typography>
      <img
        style={{ marginTop: 10 }}
        width='400'
        src='https://res.cloudinary.com/casinogrounds/image/upload/v1622551210/reactapp/Screenshot_2021-06-01_at_14.38.15.png'
        alt=''
      />

      <Typography variant='h5' style={{ marginTop: 20 }}>
        Small Bonus Hunt Overlay
      </Typography>
      <Typography>Recommended resolution: 600x150px</Typography>
      <Typography
        variant={'subtitle2'}
        style={{ maxWidth: '100vw', overflow: 'scroll' }}
      >{`https://obs.streamers.casinogrounds.com/hunts-small/${user.streamerKey}`}</Typography>
      <img
        style={{ marginTop: 10 }}
        width='400'
        src='https://res.cloudinary.com/casinogrounds/image/upload/v1622550607/reactapp/Screenshot_2021-06-01_at_14.29.13.png'
        alt=''
      />

      <Typography variant='h5' style={{ marginTop: 20 }}>
        Bonus Hunt Result / Winner Overlay
      </Typography>
      <Typography>Recommended resolution: 610x250px</Typography>
      <Typography
        variant={'subtitle2'}
        style={{ maxWidth: '100vw', overflow: 'scroll' }}
      >{`https://obs.streamers.casinogrounds.com/hunts-winner/${user.streamerKey}`}</Typography>
      <img
        style={{ marginTop: 10 }}
        width='400'
        src='https://res.cloudinary.com/casinogrounds/image/upload/v1626937751/reactapp/winner-hunt.png'
        alt=''
      />

      <Typography variant='h5' style={{ marginTop: 20 }}>
        Tournament Leaderboard Overlay
      </Typography>
      <Typography>Recommended resolution: 1344x1680px</Typography>
      <Typography
        variant={'subtitle2'}
        style={{ maxWidth: '100vw', overflow: 'scroll' }}
      >{`https://obs.streamers.casinogrounds.com/tournaments/{tournament_id}`}</Typography>
      <img
        style={{ marginTop: 10 }}
        width='400'
        src='https://res.cloudinary.com/casinogrounds/image/upload/cg/tournament_overlay.png'
        alt=''
      />
    </div>
  );
};
export default Overlays;
