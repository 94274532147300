import Layout from './components/Layout';
import Loading from './components/FSLoader';
import Navigation from './Navigation';
import { useAuth0 } from '@auth0/auth0-react';

function App() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Layout>
      <Navigation />
    </Layout>
  );
}

export default App;
