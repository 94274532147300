import { useAuth0 } from "@auth0/auth0-react";

export const useAccessToken = () => {
  const { getAccessTokenSilently } = useAuth0();

  return async () =>
    await getAccessTokenSilently({
      audience: "https://streamers.casinogrounds.com",
      scope: "offline_access openid profile email username clientid",
    });
};
