import { DataGrid } from '@material-ui/data-grid';
import React from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';
import { useAPI } from '../hooks/useAPI';
import { useHistory } from 'react-router-dom';

const columns = [
  {
    field: 'created_at_format',
    headerName: 'Created',
    width: 150,
    sortComparator: (v1, v2, param1, param2) =>
      new Date(param1.api.getCellValue(param1.id, 'createdAt')).getTime() -
      new Date(param2.api.getCellValue(param2.id, 'createdAt')).getTime(),
    valueGetter: params =>
      `${format(new Date(params.getValue('createdAt')), 'MMMM dd HH:mm')} `,
  },
  {
    field: 'streamername',
    headerName: 'Streamer',
    width: 150,
    valueGetter: params => '' + params.getValue('streamer').username,
  },
  { field: 'title', headerName: 'Title', width: 150 },
  {
    field: 'numSlots',
    headerName: 'Slots',
    width: 100,
    valueGetter: params => '' + params.getValue('slots').length,
  },
  {
    field: 'creator',
    headerName: 'Creator',
    width: 150,
    valueGetter: params => '' + params.getValue('createdBy').username,
  },
];

const StyledDataGrid = styled(DataGrid)`
  .MuiDataGrid-row {
    cursor: pointer;
  }
`;

const HuntsList = () => {
  const history = useHistory();
  const hunts = useAPI(
    '/hunts',
    {},
    {
      limit: 1000,
      page: 1,
    }
  );

  if (hunts.loading) return <div>loading</div>;

  return (
    <div>
      <div style={{ height: 600, width: '100%', maxWidth: '800px' }}>
        <StyledDataGrid
          rows={hunts.data.docs.map(d => ({ ...d, id: d._id }))}
          columns={columns}
          autoPageSize
          onSelectionModelChange={newSelection => {
            history.push('/hunts/' + newSelection.selectionModel[0]);
          }}
          sortModel={[
            {
              field: 'created_at_format',
              sort: 'desc',
            },
          ]}
        />
      </div>
    </div>
  );
};

export default HuntsList;
