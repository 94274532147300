import { useHistory, useParams } from 'react-router-dom';

import NewTournamentForm from '../../components/NewTournamentForm';
import React from 'react';
import { Typography } from '@material-ui/core';
import { useAPI } from '../../hooks/useAPI';

const EditTournament = () => {
  const history = useHistory();
  let { id } = useParams();

  const tournamentData = useAPI(id ? `/tournaments/${id}` : null);

  return (
    <div>
      <Typography variant='h4'>
        {id ? 'Edit Tournament' : 'Create Tournament'}
      </Typography>

      {!id && (
        <NewTournamentForm onSubmit={() => history.push('/tournaments')} />
      )}
      {id && tournamentData.data && (
        <NewTournamentForm editRecord={{ id, values: tournamentData.data }} />
      )}
      {id && tournamentData.loading && <div>loading</div>}
    </div>
  );
};
export default EditTournament;
