import React, { useEffect, useState } from 'react';

import { fetchAPI } from '../utils/fetchAPI';
import { useAccessToken } from './useAccessToken';
import { useAuth0 } from '@auth0/auth0-react';

let userDetailsCache = null;

const isCacheValid = auth0_id => {
  if (!userDetailsCache || !auth0_id) return false;
  return auth0_id === userDetailsCache?.auth0_id;
};

export const useAuthData = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const getAccessTokenSilently = useAccessToken();
  const [userInfo, setUserInfo] = useState(
    isCacheValid(user?.sub) ? userDetailsCache : null
  );

  useEffect(
    () => {
      (async () => {
        if (!user) {
        } else if (isCacheValid(user.sub)) {
          setUserInfo(userDetailsCache);
        } else {
          const token = await getAccessTokenSilently();
          const responseData = await fetchAPI(token, '/users/me');
          userDetailsCache = responseData;
          setUserInfo(responseData);
        }
      })();
    },
    // TODO: refactor this code and remove eslint disabled rule
    // eslint-disable-next-line
    [user]
  );

  let isFetching = isLoading;
  if (!userInfo && isAuthenticated) {
    isFetching = true;
  }

  return {
    isAuthenticated,
    isLoading: isFetching,
    user: userInfo,
    logout,
  };
};

export const withAuthData = (Component, options) => props => {
  const { isLoading, user } = useAuthData();

  if (isLoading && !user) return options.loadingComponent();

  return <Component {...props} />;
};
