import { useEffect, useState } from 'react';

import { fetchAPI } from '../utils/fetchAPI';
import { useAccessToken } from './useAccessToken';

export const useAPI = (path, options, query) => {
  const getAccessTokenSilently = useAccessToken();
  const [reFetchSwitch, setReFetchSwitch] = useState(false);
  const [fetchState, setFetchState] = useState({
    loading: true,
    error: null,
    data: null,
  });

  const optionsDepCheck = JSON.stringify(options);
  const queryDepCheck = JSON.stringify(query);

  useEffect(
    () => {
      (async () => {
        if (path) {
          setFetchState({ loading: true });
          const token = await getAccessTokenSilently();
          const response = await fetchAPI(token, path, options, query);
          setFetchState({ loading: false, data: response });
        }
      })();
    },

    // TODO: refactor this code and remove eslint disabled rule
    // eslint-disable-next-line
    [path, optionsDepCheck, queryDepCheck, reFetchSwitch]
  );

  return { ...fetchState, reFetch: () => setReFetchSwitch(!reFetchSwitch) };
};
