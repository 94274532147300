import { fetchAPI } from '../utils/fetchAPI';
import { useAccessToken } from './useAccessToken';

export const useFetchAPI = () => {
  const getAccessTokenSilently = useAccessToken();

  return async (path, options, query) => {
    const token = await getAccessTokenSilently();
    const response = await fetchAPI(token, path, options, query);
    return response;
  };
};
