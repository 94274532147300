export const fetchAPI = async (token, path, options = {}, query = null) => {
  let queryString = '';
  if (query) {
    queryString = `?${Object.keys(query)
      .map(key => key + '=' + query[key])
      .join('&')}`;
  }

  const api = process.env.REACT_APP_API;

  const response = await fetch(`${api}${path}${queryString}`, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      ...(options?.headers || {}),
    },
  });

  console.log('API: ', `${api}${path}${queryString}`, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      ...(options?.headers || {}),
    },
  });

  const responseData = await response.json();

  console.log('response: ', responseData);
  return responseData;
};
