import React, { useState } from 'react';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Loader from '../components/Loader';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useAPI } from '../hooks/useAPI';
import { useAuthData } from '../hooks/useAuth';
import { useFetchAPI } from '../hooks/useFetchAPI';

const StyledList = styled(List)`
  max-width: 600px;
  background-color: #424242;
`;

const Spacer = styled.div`
  height: ${({ height }) => height || '20'}px;
`;

const SearchField = styled(TextField)`
  width: 300px;
`;

const Streamers = () => {
  const { user } = useAuthData();
  const fetchAPI = useFetchAPI();
  const [searchResults, setSearchResults] = useState();
  const [loading, setLoading] = useState(false);
  const [searched, setSearched] = useState(false);

  const streamers = useAPI('/streamers/');

  if (!user) return null;
  return (
    <div>
      <Typography variant='h4'>Add Streamers</Typography>
      {streamers.data?.length > 0 && (
        <StyledList>
          {streamers.data.map(u => (
            <ListItem key={u.username}>
              <ListItemText primary={u.username} />
              <ListItemText primary={u.streamerKey} />
            </ListItem>
          ))}
        </StyledList>
      )}
      <Spacer />
      <Typography variant='h5'>Add Streamer:</Typography>
      <Spacer height={10} />

      <SearchField
        label='Search for User'
        variant='outlined'
        onChange={async e => {
          const term = e.target.value;
          if (term.length < 1) return null;
          setSearched(true);
          const ts = new Date().getTime();
          setLoading(ts);
          const result = await fetchAPI('/users/search', {
            method: 'POST',
            body: JSON.stringify({ term, ts }),
          });

          if (loading <= result.ts) {
            setLoading(false);
          }

          if (!searchResults || searchResults.ts <= result.ts) {
            setSearchResults(result);
          }
        }}
      />
      {loading && <Loader />}
      {searched && (
        <StyledList>
          {!searchResults?.result.length && (
            <ListItem>
              <ListItemText primary={'no results'} />
            </ListItem>
          )}
          {searchResults?.result.map(u => {
            let addable = true;
            if (
              streamers.data?.filter(s => s.username === u.username).length > 0
            ) {
              addable = false;
            }

            return (
              <ListItem key={u.username}>
                <ListItemText primary={u.username} />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={async () => {
                      await fetchAPI('/streamers', {
                        method: 'POST',
                        body: JSON.stringify({ streamerId: u.auth0_id }),
                      });
                      streamers.reFetch();
                    }}
                    disabled={!addable}
                  >
                    <AddCircleIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </StyledList>
      )}
    </div>
  );
};
export default Streamers;
