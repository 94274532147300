import { makeStyles, useTheme } from '@material-ui/core/styles';

import AccountCircle from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import AppsIcon from '@material-ui/icons/Apps';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import LeaderboardIcon from '@material-ui/icons/EmojiEvents';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import MenuIcon from '@material-ui/icons/Menu';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PictureInPictureAltIcon from '@material-ui/icons/PictureInPictureAlt';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { useAuthData } from '../hooks/useAuth';
import { useHistory } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const CGBar = styled.div`
  height: 3px;
  background-image: linear-gradient(to left, #1e00ff, #5edaf0 52%, #8af3bc);
`;

const Layout = props => {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const history = useHistory();
  const { user, logout } = useAuthData();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navigate = path => {
    history.push(path);
    setMobileOpen(false);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <ListItem button onClick={() => navigate('/')}>
          <ListItemIcon>
            <AppsIcon />
          </ListItemIcon>
          <ListItemText primary={'Home'} />
        </ListItem>
        {(user?.roles.isStreamer ||
          user?.roles.isModerator ||
          user?.roles.isAdmin) && (
          <ListItem button onClick={() => navigate('/hunts')}>
            <ListItemIcon>
              <LocalActivityIcon />
            </ListItemIcon>
            <ListItemText primary={'Bonus Hunts'} />
          </ListItem>
        )}
        {(user?.roles.isModerator || user?.roles.isAdmin) && (
          <ListItem button onClick={() => navigate('/tournaments')}>
            <ListItemIcon>
              <LeaderboardIcon />
            </ListItemIcon>
            <ListItemText primary={'Tournaments'} />
          </ListItem>
        )}
        {user?.roles.isStreamer && (
          <ListItem button onClick={() => navigate('/overlays')}>
            <ListItemIcon>
              <PictureInPictureAltIcon />
            </ListItemIcon>
            <ListItemText primary={'Stream Overlays'} />
          </ListItem>
        )}
        {user?.roles.isStreamer && (
          <ListItem button onClick={() => navigate('/mymods')}>
            <ListItemIcon>
              <AssignmentIndIcon />
            </ListItemIcon>
            <ListItemText primary={'My Moderators'} />
          </ListItem>
        )}
        {user?.roles.isAdmin && (
          <ListItem button onClick={() => navigate('/streamers')}>
            <ListItemIcon>
              <PersonAddIcon />
            </ListItemIcon>
            <ListItemText primary={'Streamers'} />
          </ListItem>
        )}
      </List>
      <Divider />
      <List>
        <ListItem
          button
          onClick={() =>
            logout({
              returnTo: window?.location.origin,
            })
          }
        >
          <ListItemIcon>
            <MeetingRoomIcon />
          </ListItemIcon>
          <ListItemText primary={'Logout'} />
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <AppBar position='fixed' className={classes.appBar} color='secondary'>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap style={{ flexGrow: 1 }}>
            CG Streamers
          </Typography>

          <Typography variant='caption' noWrap>
            {user?.username}
          </Typography>
          <IconButton color='inherit'>
            <AccountCircle />
          </IconButton>
        </Toolbar>
        <CGBar></CGBar>
      </AppBar>
      <nav className={classes.drawer} aria-label='mailbox folders'>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation='css'>
          <Drawer
            container={container}
            variant='temporary'
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation='css'>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant='permanent'
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
};

export default Layout;
