import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import React from 'react';
import TournamentsList from '../../components/TournamentsList';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const Spacer = styled.div`
  height: ${({ height }) => height || '20'}px;
`;

const Tournaments = () => {
  const history = useHistory();

  return (
    <div>
      <Typography variant='h4'>Tournaments</Typography>
      <Spacer />
      <Button
        variant='contained'
        color='primary'
        startIcon={<AddIcon />}
        onClick={() => history.push('/tournaments/new')}
      >
        New Tournament
      </Button>
      <Spacer height={30} />
      <Typography variant='h5'>Tournaments</Typography>
      <Spacer height={10} />
      <TournamentsList />
    </div>
  );
};

export default Tournaments;
