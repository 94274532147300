import React from 'react';
import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../components/FSLoader';
import { withAuthData } from '../hooks/useAuth';

const ProtectedRoute = ({ component, ...args }) => (
  <Route
    component={withAuthenticationRequired(
      withAuthData(component, { loadingComponent: () => <Loading /> }),
      {
        onRedirecting: () => <Loading />,
      }
    )}
    {...args}
  />
);

export default ProtectedRoute;
