import React from 'react';
import { Typography } from '@material-ui/core';
import { useAuthData } from '../hooks/useAuth';

const Home = () => {
  const { user } = useAuthData();

  if (!user) return null;
  return (
    <div>
      <Typography variant='h3' style={{ marginBottom: 30 }}>{`Hello ${
        user.username.charAt(0).toUpperCase() + user.username.slice(1)
      }`}</Typography>

      {user.roles.isAdmin && (
        <Typography variant='body1'>
          Your current role is "Admin". You can create and manage Bonus Hunts
          for any streamer, assign moderators to them, and promote users to
          streamer. Additionally, you can create and manage Tournaments.
        </Typography>
      )}

      {!user.roles.isAdmin && user.roles.isStreamer && (
        <Typography variant='body1'>
          Your current role is "Streamer". You can create Bonus Hunts and assign
          moderators to let them manage Bonus hunts for you.
        </Typography>
      )}

      {!user.roles.isAdmin &&
        !user.roles.isStreamer &&
        user.roles.isModerator && (
          <Typography variant='body1'>
            Your current role is "Moderator". You can create and edit Bonus
            Hunts for the streamers you are assigned to. Additionally, you can
            create and manage Tournaments.
          </Typography>
        )}

      {!user.roles.isAdmin &&
        !user.roles.isStreamer &&
        !user.roles.isModerator && (
          <Typography variant='body1'>
            You currently have no role assigned to you. Once a moderator assigns
            you as their moderator, you will be able to create and edit Bonus
            Hunts for them. If you are a streamer, please contact an
            administrator to request streamer privileges.
          </Typography>
        )}
    </div>
  );
};
export default Home;
