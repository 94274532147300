import Oval from 'react-preloader-icon/loaders/Oval';
import PreloaderIcon from 'react-preloader-icon';
import styled from 'styled-components';

const PreloaderContainer = styled.div`
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = () => {
  return (
    <PreloaderContainer>
      <PreloaderIcon
        loader={Oval}
        size={80}
        strokeWidth={15}
        strokeColor='#4740A6'
        duration={800}
      />
    </PreloaderContainer>
  );
};
export default Loader;
