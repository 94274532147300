import Oval from 'react-preloader-icon/loaders/Oval';
import PreloaderIcon from 'react-preloader-icon';
import styled from 'styled-components';

const PreloaderContainer = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FSLoader = () => {
  return (
    <PreloaderContainer>
      <PreloaderIcon
        loader={Oval}
        size={80}
        strokeWidth={15}
        strokeColor='#4740A6'
        duration={800}
      />
    </PreloaderContainer>
  );
};
export default FSLoader;
